import 'reflect-metadata'
import { Container } from 'inversify'
import symbols from '@/symbols'
import {
  IAddAngelListsUseCase,
  IAddCommentToAngelUseCase,
  IAddCommentToCorporateInvestorUseCase,
  IAddCorporateInvestorListsUseCase,
  IAddMemberInvitationUseCase,
  IAngel,
  IAngelFactory,
  IAngelsService,
  IAngelsStore,
  IAppCredentials,
  IAppErrorFactory,
  IAttackListEntityFactory,
  IAuthService,
  IChangeLanguageUseCase,
  ICommentsService,
  ICommentsStore,
  ICorporateInvestor,
  ICorporateInvestorFactory,
  ICorporateInvestorListFactory,
  ICorporateInvestorsService,
  ICorporateInvestorsStore,
  IErrorsStore,
  IErrorTrackingService,
  IFetchAngelsUseCase,
  IFetchAngelUseCase,
  IFetchAttackListsUseCase,
  IFetchCommentsUseCase,
  IFetchCorporateInvestorCountsUseCase,
  IFetchCorporateInvestorListsUseCase,
  IFetchCorporateInvestorsUseCase,
  IFetchCorporateInvestorUseCase,
  IFetchDashboardUseCase,
  IFetchExternalLinksUseCase,
  IFetchLocationsUseCase,
  IFetchMarketsUseCase,
  IFetchRoundsUseCase,
  IInitializeUseCase,
  ILocationsService,
  IMarketsService,
  IMessageFactory,
  IMessagesStore,
  IPreferences,
  IRemoveAttackListsUseCase,
  IRemoveCommentToAngelUseCase,
  IRemoveCommentToCorporateInvestorUseCase,
  IRoundsService,
  ISearchUserByEmailUseCase,
  ISendResetPasswordRequestUseCase,
  ISignInUseCase,
  ISignOutUseCase,
  ISignUpUseCase,
  IStorageService,
  ISubscriptionsService,
  IToggleAngelListUseCase,
  IToggleCorporateInvestorListUseCase,
  IUIStore,
  IUpdateAngelListUseCase,
  IUpdateCommentToAngelUseCase,
  IUpdateCommentToCorporateInvestorUseCase,
  IUpdateCorporateInvestorListUseCase,
  IUpdateEmailUseCase,
  IUpdateMeUseCase,
  IUpdateMyProfileUseCase,
  IUpdatePasswordUseCase,
  IUpdatePasswordWithTokenUseCase,
  IUseCaseOutputFactory,
  IUserMemberFactory,
  IUserProfileFactory,
  IUsersService,
  IUsersStore,
  IValidInvitationTokenUseCase,
  IViewer,
  IViewerService,
  IViewerStore,
} from '@/types'
import UIStore from '@/stores/UIStore'
import MessagesStore from '@/stores/MessagesStore'
import ErrorsStore from '@/stores/ErrorsStore'
import MessageFactory from '@/factories/MessageFactory'
import ViewerStore from '@/stores/ViewerStore'
import AppErrorFactory from '@/factories/AppErrorFactory'
import AuthAPIGateway from '@/gateways/AuthAPIGateway'
import SignInInteractor from '@/useCases/viewer/SignInInteractor'
import LocalStorageGateway from '@/gateways/LocalStorageGateway'
import InitializeInteractor from '@/useCases/viewer/InitializeInteractor'
import AppAPIGateway from '@/gateways/AppAPIGateway'
import SignOutInteractor from '@/useCases/viewer/SignOutInteractor'
import SignUpInteractor from '@/useCases/viewer/SignUpInteractor'
import AppCredentials from '@/entities/AppCredentials'
import UpdatePasswordInteractor from '@/useCases/viewer/UpdatePasswordInteractor'
import UpdateEmailInteractor from '@/useCases/viewer/UpdateEmailInteractor'
import SendResetPasswordRequestInteractor from '@/useCases/viewer/SendResetPasswordRequestInteractor'
import Preferences from '@/entities/Preferences'
import UpdatePasswordWithTokenInteractor from '@/useCases/viewer/UpdatePasswordWithTokenInteractor'
import UpdateMeInteractor from '@/useCases/viewer/UpdateMeInteractor'
import FetchMarketsInteractor from '@/useCases/markets/FetchMarketsInteractor'
import Viewer from '@/entities/Viewer'
import SentryNextjsGateway from '@/gateways/SentryNextjsGateway'
import UseCaseOutputFactory from '@/factories/UseCaseOutputFactory'
import ChangeLanguageInteractor from '@/useCases/viewer/ChangeLanguageInteractor'
import FetchRoundsInteractor from '@/useCases/rounds/FetchRoundsInteractor'
import FetchCorporateInvestorsInteractor from '@/useCases/corporateInvestors/FetchCorporateInvestorsInteractor'
import FetchCorporateInvestorInteractor from '@/useCases/corporateInvestors/FetchCorporateInvestorInteractor'
import CorporateInvestorsStore from '@/stores/CorporateInvestorsStore'
import FetchAngelsInteractor from '@/useCases/angels/FetchAngelsInteractor'
import FetchAngelInteractor from '@/useCases/angels/FetchAngelInteractor'
import AngelsStore from '@/stores/AngelsStore'
import ToggleAngelListInteractor from './useCases/angels/ToggleAngelListInteractor'
import ToggleCorporateInvestorListInteractor from './useCases/corporateInvestors/ToggleCorporateInvestorListInteractor'
import AngelFactory from './factories/AngelFactory'
import CorporateInvestorFactory from './factories/CorporateInvestorFactory'
import CorporateInvestor from './entities/CorporateInvestor'
import Angel from './entities/Angel'
import AttackListEntityFactory from './factories/AttackListEntityFactory'
import FetchAttackListsInteractor from './useCases/viewer/FetchAttackListsInteractor'
import UpdateCorporateInvestorListInteractor from './useCases/corporateInvestors/UpdateCorporateInvestorListInteractor'
import UpdateAngelListInteractor from './useCases/angels/UpdateAngelListInteractor'
import { IUserMembersService } from './types/services/userMembers'
import UserMemberFactory from './factories/UserMemberFactory'
import {
  IAddUserMemberUseCase,
  IFetchUserMembersUseCase,
  IRemoveUserMemberUseCase,
  IUpdateUserMemberUseCase,
} from './types/useCases/userMembers'
import FetchUserMembersInteractor from './useCases/userMembers/FetchUserMembersInteractor'
import AddUserMemberInteractor from './useCases/userMembers/AddUserMemberInteractor'
import UpdateUserMemberInteractor from './useCases/userMembers/UpdateUserMemberInteractor'
import RemoveUserMemberInteractor from './useCases/userMembers/RemoveUserMemberInteractor'
import UsersStore from './stores/UsersStore'
import FetchDashboardInteractor from './useCases/viewer/FetchDashboardInteractor'
import AddCommentToAngelInteractor from './useCases/comments/AddCommentToAngelInteractor'
import RemoveCommentToAngelInteractor from './useCases/comments/RemoveCommentToAngelInteractor'
import UpdateCommentToAngelInteractor from './useCases/comments/UpdateCommentToAngelInteractor'
import AddCommentToCorporateInvestorInteractor from './useCases/comments/AddCommentToCorporateInvestorInteractor'
import UpdateCommentToCorporateInvestorInteractor from './useCases/comments/UpdateCommentToCorporateInvestorInteractor'
import RemoveCommentToCorporateInvestorInteractor from './useCases/comments/RemoveCommentToCorporateInvestorInteractor'
import { IInvitationsService } from './types/services/invitations'
import ValidInvitationTokenInteractor from './useCases/invitations/ValidInvitationTokenInteractor'
import AddMemberInvitationInteractor from './useCases/invitations/AddMemberInvitationInteractor'
import SearchUserByEmailInteractor from './useCases/users/SearchUserByEmailInteractor'
import AddCorporateInvestorListsInteractor from './useCases/corporateInvestors/AddCorporateInvestorListsInteractor'
import RemoveAttackListsInteractor from './useCases/viewer/RemoveAttackListsInteractor'
import CommentsStore from './stores/CommentsStore'
import FetchCommentsInteractor from './useCases/comments/FetchCommentsInteractor'
import AddAngelListsInteractor from './useCases/angels/AddAngelListsInteractor'
import UserProfileFactory from './factories/UserProfileFactory'
import {
  ICreateBillingPortalSessionUseCase,
  ICreateCheckoutSessionUseCase,
  ICreateDbSubscriptionUseCase,
} from './types/useCases/subscriptions'
import CreateBillingPortalSessionInteractor from './useCases/subscriptions/CreateBillingPortalSessionInteractor'
import CreateDbSubscriptionInteractor from './useCases/subscriptions/CreateDbSubscriptionInteractor'
import FetchLocationsInteractor from './useCases/locations/FetchLocationsInteractor'
import CreateCheckoutSessionInteractor from './useCases/subscriptions/CreateCheckoutSessionInteractor'
import UpdateMyProfilelInteractor from './useCases/viewer/UpdateMyProfilelInteractor'
import FetchCorporateInvestorListsInteractor from './useCases/viewer/FetchCorporateInvestorListsInteractor'
import CorporateInvestorListFactory from './factories/CorporateInvestorListFactory'
import FetchExternalLinksInteractor from './useCases/externalLinks/fetchExternalLinksInteractor'
import FetchCorporateInvestorCountsInteractor from './useCases/viewer/FetchCorporateInvestorCountsInteractor'

const createContainer = (container: Container): Container => {
  // Stores
  container.bind<IUIStore>(symbols.IUIStore).to(UIStore).inSingletonScope()
  container.bind<IMessagesStore>(symbols.IMessagesStore).to(MessagesStore).inSingletonScope()
  container.bind<IErrorsStore>(symbols.IErrorsStore).to(ErrorsStore).inSingletonScope()
  container.bind<IViewerStore>(symbols.IViewerStore).to(ViewerStore).inSingletonScope()
  container
    .bind<ICorporateInvestorsStore>(symbols.ICorporateInvestorsStore)
    .to(CorporateInvestorsStore)
    .inSingletonScope()
  container.bind<IAngelsStore>(symbols.IAngelsStore).to(AngelsStore).inSingletonScope()
  container.bind<IUsersStore>(symbols.IUsersStore).to(UsersStore).inSingletonScope()
  container.bind<ICommentsStore>(symbols.ICommentsStore).to(CommentsStore).inSingletonScope()

  // Entities
  container.bind<IAppCredentials>(symbols.IAppCredentials).to(AppCredentials).inSingletonScope()
  container.bind<IPreferences>(symbols.IPreferences).to(Preferences).inSingletonScope()
  container.bind<IViewer>(symbols.IViewer).to(Viewer).inSingletonScope()
  container.bind<ICorporateInvestor>(symbols.ICorporateInvestor).to(CorporateInvestor).inSingletonScope()
  container.bind<IAngel>(symbols.IAngel).to(Angel).inSingletonScope()

  // Factories
  container.bind<IAppErrorFactory>(symbols.IAppErrorFactory).to(AppErrorFactory).inSingletonScope()
  container
    .bind<IUseCaseOutputFactory<never>>(symbols.IUseCaseOutputFactory)
    .to(UseCaseOutputFactory)
    .inSingletonScope()
  container.bind<IMessageFactory>(symbols.IMessageFactory).to(MessageFactory).inSingletonScope()
  container.bind<IAngelFactory>(symbols.IAngelFactory).to(AngelFactory).inSingletonScope()
  container
    .bind<ICorporateInvestorFactory>(symbols.ICorporateInvestorFactory)
    .to(CorporateInvestorFactory)
    .inSingletonScope()
  container
    .bind<IAttackListEntityFactory>(symbols.IAttackListEntityFactory)
    .to(AttackListEntityFactory)
    .inSingletonScope()
  container
    .bind<ICorporateInvestorListFactory>(symbols.ICorporateInvestorListFactory)
    .to(CorporateInvestorListFactory)
    .inSingletonScope()
  container.bind<IUserMemberFactory>(symbols.IUserMemberFactory).to(UserMemberFactory).inSingletonScope()
  container.bind<IUserProfileFactory>(symbols.IUserProfileFactory).to(UserProfileFactory).inSingletonScope()

  // Binding Services to Gateways
  container.bind<IStorageService>(symbols.IStorageService).to(LocalStorageGateway).inSingletonScope()
  container.bind<IAuthService>(symbols.IAuthService).to(AuthAPIGateway).inSingletonScope()
  container.bind<IViewerService>(symbols.IViewerService).to(AppAPIGateway).inSingletonScope()
  container.bind<IMarketsService>(symbols.IMarketsService).to(AppAPIGateway).inSingletonScope()
  container.bind<IRoundsService>(symbols.IRoundsService).to(AppAPIGateway).inSingletonScope()
  container.bind<ICorporateInvestorsService>(symbols.ICorporateInvestorsService).to(AppAPIGateway).inSingletonScope()
  container.bind<IAngelsService>(symbols.IAngelsService).to(AppAPIGateway).inSingletonScope()
  container.bind<IUserMembersService>(symbols.IUserMembersService).to(AppAPIGateway).inSingletonScope()
  container.bind<IInvitationsService>(symbols.IInvitationsService).to(AppAPIGateway).inSingletonScope()
  container.bind<IUsersService>(symbols.IUsersService).to(AppAPIGateway).inSingletonScope()
  container.bind<ICommentsService>(symbols.ICommentsService).to(AppAPIGateway).inSingletonScope()
  container.bind<IErrorTrackingService>(symbols.IErrorTrackingService).to(SentryNextjsGateway).inSingletonScope()
  container.bind<ISubscriptionsService>(symbols.ISubscriptionsService).to(AppAPIGateway).inSingletonScope()
  container.bind<ILocationsService>(symbols.ILocationsService).to(AppAPIGateway).inSingletonScope()

  // Binding UseCases to Interactors
  container.bind<IInitializeUseCase>(symbols.IInitializeUseCase).to(InitializeInteractor).inSingletonScope()
  container.bind<ISignInUseCase>(symbols.ISignInUseCase).to(SignInInteractor).inSingletonScope()
  container.bind<ISignUpUseCase>(symbols.ISignUpUseCase).to(SignUpInteractor).inSingletonScope()
  container.bind<ISignOutUseCase>(symbols.ISignOutUseCase).to(SignOutInteractor).inSingletonScope()
  container.bind<IUpdatePasswordUseCase>(symbols.IUpdatePasswordUseCase).to(UpdatePasswordInteractor).inSingletonScope()
  container
    .bind<IUpdatePasswordWithTokenUseCase>(symbols.IUpdatePasswordWithTokenUseCase)
    .to(UpdatePasswordWithTokenInteractor)
    .inSingletonScope()
  container.bind<IUpdateEmailUseCase>(symbols.IUpdateEmailUseCase).to(UpdateEmailInteractor).inSingletonScope()
  container.bind<IUpdateMeUseCase>(symbols.IUpdateMeUseCase).to(UpdateMeInteractor).inSingletonScope()
  container
    .bind<ISendResetPasswordRequestUseCase>(symbols.ISendResetPasswordRequestUseCase)
    .to(SendResetPasswordRequestInteractor)
    .inSingletonScope()
  container.bind<IChangeLanguageUseCase>(symbols.IChangeLanguageUseCase).to(ChangeLanguageInteractor).inSingletonScope()
  container.bind<IFetchMarketsUseCase>(symbols.IFetchMarketsUseCase).to(FetchMarketsInteractor).inSingletonScope()
  container.bind<IFetchRoundsUseCase>(symbols.IFetchRoundsUseCase).to(FetchRoundsInteractor).inSingletonScope()
  container
    .bind<IFetchCorporateInvestorsUseCase>(symbols.IFetchCorporateInvestorsUseCase)
    .to(FetchCorporateInvestorsInteractor)
    .inSingletonScope()
  container
    .bind<IFetchCorporateInvestorUseCase>(symbols.IFetchCorporateInvestorUseCase)
    .to(FetchCorporateInvestorInteractor)
    .inSingletonScope()
  container.bind<IFetchAngelsUseCase>(symbols.IFetchAngelsUseCase).to(FetchAngelsInteractor).inSingletonScope()
  container.bind<IFetchAngelUseCase>(symbols.IFetchAngelUseCase).to(FetchAngelInteractor).inSingletonScope()
  container
    .bind<IToggleAngelListUseCase>(symbols.IToggleAngelListUseCase)
    .to(ToggleAngelListInteractor)
    .inSingletonScope()
  container
    .bind<IToggleCorporateInvestorListUseCase>(symbols.IToggleCorporateInvestorListUseCase)
    .to(ToggleCorporateInvestorListInteractor)
    .inSingletonScope()
  container
    .bind<IFetchAttackListsUseCase>(symbols.IFetchAttackListsUseCase)
    .to(FetchAttackListsInteractor)
    .inSingletonScope()
  container
    .bind<IFetchCorporateInvestorListsUseCase>(symbols.IFetchCorporateInvestorListsUseCase)
    .to(FetchCorporateInvestorListsInteractor)
    .inSingletonScope()
  container
    .bind<IUpdateCorporateInvestorListUseCase>(symbols.IUpdateCorporateInvestorListUseCase)
    .to(UpdateCorporateInvestorListInteractor)
    .inSingletonScope()
  container
    .bind<IUpdateAngelListUseCase>(symbols.IUpdateAngelListUseCase)
    .to(UpdateAngelListInteractor)
    .inSingletonScope()
  container
    .bind<IFetchUserMembersUseCase>(symbols.IFetchUserMembersUseCase)
    .to(FetchUserMembersInteractor)
    .inSingletonScope()
  container.bind<IAddUserMemberUseCase>(symbols.IAddUserMemberUseCase).to(AddUserMemberInteractor).inSingletonScope()
  container
    .bind<IUpdateUserMemberUseCase>(symbols.IUpdateUserMemberUseCase)
    .to(UpdateUserMemberInteractor)
    .inSingletonScope()
  container
    .bind<IRemoveUserMemberUseCase>(symbols.IRemoveUserMemberUseCase)
    .to(RemoveUserMemberInteractor)
    .inSingletonScope()
  container
    .bind<ISearchUserByEmailUseCase>(symbols.ISearchUserByEmailUseCase)
    .to(SearchUserByEmailInteractor)
    .inSingletonScope()
  container.bind<IFetchDashboardUseCase>(symbols.IFetchDashboardUseCase).to(FetchDashboardInteractor).inSingletonScope()
  container
    .bind<IFetchCorporateInvestorCountsUseCase>(symbols.IFetchCorporateInvestorCountsUseCase)
    .to(FetchCorporateInvestorCountsInteractor)
    .inSingletonScope()
  container.bind<IFetchCommentsUseCase>(symbols.IFetchCommentsUseCase).to(FetchCommentsInteractor).inSingletonScope()
  container
    .bind<IAddCommentToCorporateInvestorUseCase>(symbols.IAddCommentToCorporateInvestorUseCase)
    .to(AddCommentToCorporateInvestorInteractor)
    .inSingletonScope()
  container
    .bind<IUpdateCommentToCorporateInvestorUseCase>(symbols.IUpdateCommentToCorporateInvestorUseCase)
    .to(UpdateCommentToCorporateInvestorInteractor)
    .inSingletonScope()
  container
    .bind<IRemoveCommentToCorporateInvestorUseCase>(symbols.IRemoveCommentToCorporateInvestorUseCase)
    .to(RemoveCommentToCorporateInvestorInteractor)
    .inSingletonScope()
  container
    .bind<IAddCommentToAngelUseCase>(symbols.IAddCommentToAngelUseCase)
    .to(AddCommentToAngelInteractor)
    .inSingletonScope()
  container
    .bind<IUpdateCommentToAngelUseCase>(symbols.IUpdateCommentToAngelUseCase)
    .to(UpdateCommentToAngelInteractor)
    .inSingletonScope()
  container
    .bind<IRemoveCommentToAngelUseCase>(symbols.IRemoveCommentToAngelUseCase)
    .to(RemoveCommentToAngelInteractor)
    .inSingletonScope()
  container
    .bind<IValidInvitationTokenUseCase>(symbols.IValidInvitationTokenUseCase)
    .to(ValidInvitationTokenInteractor)
    .inSingletonScope()
  container
    .bind<IAddMemberInvitationUseCase>(symbols.IAddMemberInvitationUseCase)
    .to(AddMemberInvitationInteractor)
    .inSingletonScope()
  container
    .bind<IAddCorporateInvestorListsUseCase>(symbols.IAddCorporateInvestorListsUseCase)
    .to(AddCorporateInvestorListsInteractor)
    .inSingletonScope()
  container
    .bind<IRemoveAttackListsUseCase>(symbols.IRemoveAttackListsUseCase)
    .to(RemoveAttackListsInteractor)
    .inSingletonScope()
  container.bind<IAddAngelListsUseCase>(symbols.IAddAngelListsUseCase).to(AddAngelListsInteractor).inSingletonScope()
  container
    .bind<ICreateBillingPortalSessionUseCase>(symbols.ICreateBillingPortalSessionUseCase)
    .to(CreateBillingPortalSessionInteractor)
    .inSingletonScope()
  container
    .bind<ICreateDbSubscriptionUseCase>(symbols.ICreateDbSubscriptionUseCase)
    .to(CreateDbSubscriptionInteractor)
    .inSingletonScope()
  container
    .bind<ICreateCheckoutSessionUseCase>(symbols.ICreateCheckoutSessionUseCase)
    .to(CreateCheckoutSessionInteractor)
    .inSingletonScope()
  container.bind<IFetchLocationsUseCase>(symbols.IFetchLocationsUseCase).to(FetchLocationsInteractor).inSingletonScope()
  container
    .bind<IUpdateMyProfileUseCase>(symbols.IUpdateMyProfileUseCase)
    .to(UpdateMyProfilelInteractor)
    .inSingletonScope()
  container
    .bind<IFetchExternalLinksUseCase>(symbols.IFetchExternalLinksUseCase)
    .to(FetchExternalLinksInteractor)
    .inSingletonScope()

  return container
}

const emptyContainer = new Container()
const container = createContainer(emptyContainer)

export { container, createContainer }

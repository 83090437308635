import { action, observable } from 'mobx'
import remotedev, { RemoteDevConfig } from 'mobx-remotedev'
import { injectable } from 'inversify'
import { IUIStore } from '@/types'

const remoteDevConfig: RemoteDevConfig = {
  name: 'UIStore',
  global: true,
  remote: false,
}

@remotedev(remoteDevConfig)
@injectable()
export default class UIStore implements IUIStore {
  _mobileBreakPoint = 992

  // タブレット対応するときのブレークポイント設定用
  _largeMobileBreakPoint = 992

  @observable isRightDrawerOpen = false

  @observable isContactModalOpen = false

  @observable isAttackListEntityRightDrawerOpen = false

  @observable isCommentDrawerOpen = false

  @observable isDbPaymentModalOpen = false

  @observable isLeadToContactFormModalOpen = false

  @observable isLeadToDbPaymentModalOpen = false

  get isMobile(): boolean {
    if (!process.browser) {
      return false
    }

    const viewportWidth = window.innerWidth
    if (viewportWidth >= this._mobileBreakPoint) {
      return false
    }

    return true
  }

  @action
  toggleRightDrawer = (): void => {
    this.isRightDrawerOpen = !this.isRightDrawerOpen
  }

  @action
  toggleContactModal(): void {
    this.isContactModalOpen = !this.isContactModalOpen
  }

  @action
  toggleAttackListEntityRightDrawer(): void {
    this.isAttackListEntityRightDrawerOpen = !this.isAttackListEntityRightDrawerOpen
  }

  @action
  toggleCommentDrawer(): void {
    this.isCommentDrawerOpen = !this.isCommentDrawerOpen
  }

  @action
  toggleDbPaymentModal(): void {
    this.isDbPaymentModalOpen = !this.isDbPaymentModalOpen
  }

  @action
  toggleLeadToContactFormModal(): void {
    this.isLeadToContactFormModalOpen = !this.isLeadToContactFormModalOpen
  }

  @action
  toggleLeadToDbPaymentModalOpen(): void {
    this.isLeadToDbPaymentModalOpen = !this.isLeadToDbPaymentModalOpen
  }
}

import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  IAppErrorFactory,
  IDashboardBase,
  IUseCaseOutputFactory,
  IViewerService,
  FetchCorporateInvestorCountsUseCaseOutput,
  IFetchCorporateInvestorCountsUseCase,
} from '@/types'

@injectable()
export default class FetchCorporateInvestorCountsInteractor implements IFetchCorporateInvestorCountsUseCase {
  @inject(symbols.IViewerService) private viewerService: IViewerService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    corporateInvestorCounts: IDashboardBase
  }>

  async handle(): Promise<FetchCorporateInvestorCountsUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        corporateInvestorCounts: null,
      },
    })

    try {
      const response = await this.viewerService.fetchCorporateInvestorCounts()

      output.data.corporateInvestorCounts = response
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
